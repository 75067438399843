import React, { Component } from 'react'
import * as contentful from 'contentful'
import { Link } from 'react-router-dom'
import '../../App.scss'
import logo from '../../img/logo-new.svg'
import MobileNav from '../../components/mobilenav'

export default class Workshops extends Component {
    state = {
        workshops: []
    }

    componentDidMount() {
        const client = contentful.createClient({
            space: 'flc7i8lgaghg',
            environment: 'master',
            accessToken: 'lpvop-BO7tm7MvJ9tlzT9oAvyy1OmlvZuQ9DZKiGOQg'
          })

          client.getEntries({content_type: 'workshops'})
          .then((response) => this.setState({
            workshops: response.items
          })
          )
          .catch(console.error)
    }

    toggleNav = () => {
        document.getElementById('mobileNav').style.display = "flex"
        document.getElementById('menuButton').style.display = "none"
    }

    render() {
        return (
            <div className="main">
                <div className="menuButton" id="menuButton" onClick={()=>{this.toggleNav()}}></div>
                <div className="logoMain">
                    <Link to='/'>
                        <img src={logo} alt='mem' />
                    </Link>
                    <div className="title">
                        <h2>Workshops</h2>
                    </div>
                </div>
                <MobileNav />
                <div className="left-panel prints workshops">
                    <p>
                    <h2>Workshops / Retreats / Shoots</h2>
                    El arte de conjugar todos nuestros sentidos con el espacio. Abrir la mirada, visualizar nuestra expansión, expresar lo que exclama el alma y corazón!
                    <br/>
                    <br/>
                    Espacios para fotografiar a profundidad. Donde el fotógrafo y la naturaleza se conjugan creando imágenes que inspiran y trascienden. Experiencias de inmersión en campos abiertos, momentos de contemplación y asombro para profundizar sobre el arte de la fotografía. 
                    <br/>
                    <br/>
                    Experimentaremos todo lo que nos enseña la fotografía, dejándonos llevar por los vientos, el clima, la aceptación, aprendiendo a soltar el control, estar a la espera con quietud y paciencia, para lograr esas imágenes que tanto anhelamos. 
                    <br/>
                    <br/>
                    Más que un curso, una experiencia que te cambiará tu manera de mirar y sentir la vida!
                    <br/>
                    <br/>
                    Dirigidos a personas apasionadas de la vida, estudiantes, amateurs, profesionales, pero sobretodo amantes del campo y la fotografía. Que quieran tener momentos de inspiración y presencia, aprendiendo “tips” de diferentes temas a fotografiar. 
                    </p>
                </div>
                <div className="portfolio-panel prints">
                {
                    this.state.workshops.slice(0).reverse().map((workshop) => {
                        return (
                            <div className="item" key={workshop.fields.image.fields.file.url}>
                                <img src={workshop.fields.image.fields.file.url} alt={workshop.fields.image.fields.file.url} />
                            </div>
                        );
                    })
                }
                </div>
            </div>
        )
    }
}
