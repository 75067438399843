import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../App.scss'
import logo from '../../img/logo-new.svg'
import MobileNav from '../../components/mobilenav'

export default class Contact extends Component {

    toggleNav = () => {
        document.getElementById('mobileNav').style.display = "flex"
        document.getElementById('menuButton').style.display = "none"
    }

    render() {
        return (
            <div className="main">
                <div className="menuButton" id="menuButton" onClick={()=>{this.toggleNav()}}></div>
                <div className="logoMain">
                    <Link to='/'>
                        <img src={logo} alt='mem' />
                    </Link>
                    <div className="title">
                        <h2>Contact</h2>
                    </div>
                </div>
                <MobileNav />
                <div className="about contact">
                    <div className="content">
                        <img src={logo} alt='MEM' />
                        <div className="buttons">
                            <a href="https://www.instagram.com/mariaemiliamoncayo.photo/" target="_blank" rel="noopener noreferrer">
                                <button><i className="fab fa-instagram"></i>Instagram</button>
                            </a><br/>
                            <a href="https://www.facebook.com/MEMfotografia" target="_blank" rel="noopener noreferrer">
                                <button><i className="fab fa-facebook"></i>Facebook</button>
                            </a><br/>
                            <a href="https://twitter.com/memoncayoh" target="_blank" rel="noopener noreferrer">
                                <button><i className="fab fa-twitter"></i>Twitter</button>
                            </a><br/>
                            <a href="https://wa.me/593999038381?text=Hola,%20estoy%20interesado/a%20" target="_blank" rel="noopener noreferrer">
                                <button><i className="fab fa-whatsapp"></i>Whatsapp</button>
                            </a>
                        </div>
                        <p>
                        María Emilia Moncayo / memoncayoh@gmail.com / (593) 9 99038-381
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
