import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../App.scss'
import logo from '../../img/logo-new.svg'

class home extends Component {

    componentDidMount() {
        window.setTimeout(() => {
            document.getElementById('ad').style.display = "flex";
        }, 1000);
    }

    close = () => {
        document.getElementById('ad').style.display = "none"
    }

    render() {
        return (
            <div className="main">
                <div className="ad" id="ad">
                    <div className="ad-container">
                        <div className="image"></div>
                        <a href="https://wa.me/593999038381?text=Hola,%20quisiera%20comprar%20el%20libro%20DANTZA." target="_blank" rel="noopener noreferrer">
                            <button>Comprar</button>
                        </a>
                        <Link to='/books'>
                            <button>Más información</button>
                        </Link>
                        <div className="close" onClick={()=>{ this.close() }}>
                            <div className="outer">
                            <div className="inner">
                                <label>Back</label>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="quote-desktop">
                    <span>✨✨✨Solo recordaremos lo que hemos Sentido✨✨✨</span>
                </div>
                <div className="left-panel menu-home">
                    <div className="logo">
                        <Link to='/'><img src={logo} alt="Maria Emilia Moncayo" /></Link>
                    </div>
                    <div className="quote-mobile">
                        <span>✨✨✨<br/>Solo recordaremos<br/>lo que hemos Sentido<br/>✨✨✨</span>
                    </div>
                    <nav>
                        <ul>
                            <li><Link to='/portfolio'>Portfolio</Link></li>
                            <li><Link to='/books'>Books</Link></li>
                            <li><Link to='/prints'>Fine Art</Link></li>
                            <li><Link to='/workshops'>Workshops</Link></li>
                            <li><Link to='/press'>Press</Link></li>
                            <li><Link to='/about'>About</Link></li>
                            <li><Link to='/contact'>Contact</Link></li>
                        </ul>
                    </nav>
                </div>
                <div className="images-panel">
                    <div className="small" id="portfolio">
                        <Link to='/portfolio'>Portfolio</Link>
                    </div>
                    <div className="small" id="prints" style={{ textAlign: 'center' }}>
                        <Link to='/prints'>Fine Art</Link>
                    </div>
                    <div className="large" id="workshops">
                        <Link to='/workshops'>Workshops</Link>
                    </div>
                    <div className="large" id="about">
                        <Link to='/about'>About</Link>
                    </div>
                </div>
                <div className="images-panel">
                    <div className="full" id="books">
                        <Link to='/books'>Books</Link>
                    </div>
                    <div className="small" id="press">
                        <Link to='/press'>Press</Link>
                    </div>
                    <div className="small" id="contact">
                        <Link to='/contact'>Contact</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default home;
