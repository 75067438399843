import React from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom'
import './App.scss';
import Home from './pages/home'
import Books from './pages/books'
import Portfolio from './pages/portfolio'
import Prints from './pages/prints'
import Press from './pages/press'
import About from './pages/about'
import Contact from './pages/contact'
import Workshops from './pages/workshops'

function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path='/' component={Home} />
        <Route path='/books' component={Books} />
        <Route path='/portfolio' component={Portfolio} />
        <Route path='/prints' component={Prints} />
        <Route path='/press' component={Press} />
        <Route path='/about' component={About} />
        <Route path='/contact' component={Contact} />
        <Route path='/workshops' component={Workshops} />
      </Router>
    </div>
  );
}

export default App;
