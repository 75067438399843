import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../App.scss'

export default class MobileNav extends Component {

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    close = () => {
        document.getElementById('mobileNav').style.display = "none"
        document.getElementById('menuButton').style.display = "flex"
    }

    escFunction(event){
        if(event.keyCode === 27 && document.getElementById("mobileNav").style.display === 'flex') {
            console.log('cerrar')
            document.getElementById("mobileNav").style.display = 'none'
            document.getElementById('menuButton').style.display = "block"
        }
    }

    render() {
        return (
            <div className="mobileNav" id="mobileNav">
                <nav className="nav-container">
                    <div className="close-button">
                        <div className="close" onClick={()=>{ this.close() }}>
                            <div className="outer">
                                <div className="inner">
                                    <label>Close</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul>
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/portfolio'>Portfolio</Link></li>
                        <li><Link to='/books'>Books</Link></li>
                        <li><Link to='/prints'>Prints</Link></li>
                        <li><Link to='/workshops'>Workshops</Link></li>
                        <li><Link to='/press'>Press</Link></li>
                        <li><Link to='/about'>About</Link></li>
                        <li><Link to='/contact'>Contact</Link></li>
                    </ul>
                </nav>
            </div>
        )
    }
}
