import React, { Component } from 'react'
import * as contentful from 'contentful'
import { Link } from 'react-router-dom'
import '../../App.scss'
import logo from '../../img/logo-new.svg'
import MobileNav from '../../components/mobilenav'
import Select from 'react-select'

export default class Portfolio extends Component {
    constructor() {
        super();
        this.state = {
            projects: [],
            value: { value: 'DANTZA', label: 'DANTZA' },
            options: [
                { value: 'DANTZA', label: 'DANTZA' },
                { value: 'Simplicidad', label: 'Simplicidad' },
                { value: 'Simplicidad2', label: 'Simplicidad 2' },
                { value: 'TierraChagras', label: 'Tierra de Chagras' },
                { value: 'TierraChagras2', label: 'Tierra de Chagras 2' },
                { value: 'Toquilla', label: 'Senderos de Toquilla' },
                { value: 'CuatroAndares', label: 'Cuatro Andares' },
                { value: 'CuatroAndares2', label: 'Cuatro Andares 2' },
                { value: 'AmanecerAlGalope', label: 'Amanecer al Galope' },
                { value: 'Abasolo', label: 'Abasolo' },
                { value: 'Abasolo2', label: 'Abasolo 2' },
                { value: 'Andando', label: 'Andando' },
                { value: 'Arabes', label: 'Arabes' },
                { value: 'Aereas', label: 'Aereas' },
                { value: 'Canyon', label: 'Canyon' },
                { value: 'Estero', label: 'Estero' },
                { value: 'Flamenco', label: 'Flamenco' },
                { value: 'LosCharros', label: 'Los Charros' },
                { value: 'ManadasCotopaxi', label: 'Manadas Cotopaxi' },
                { value: 'Panoramicas', label: 'Panorámicas' },
                { value: 'PeruanosPaso', label: 'Peruanos Paso' },
                { value: 'Polo', label: 'Polo' },
                { value: 'Rumicanasta', label: 'Rumicanasta' },
                { value: 'Sombras', label: 'Sombras' },
                { value: 'Yakuaya', label: 'Yakuaya' }
            ]
        }
    }

    componentDidMount() {
        const client = contentful.createClient({
            space: 'flc7i8lgaghg',
            environment: 'master',
            accessToken: 'lpvop-BO7tm7MvJ9tlzT9oAvyy1OmlvZuQ9DZKiGOQg',
          })

          client.getEntries({content_type: 'portfolio', limit: 1000})
          .then((response) => this.setState({
            projects: response.items
          })
          )
          .catch(console.error)
    }

    toggleNav = () => {
        document.getElementById('mobileNav').style.display = "flex"
        document.getElementById('menuButton').style.display = "none"
    }

    handleChange = value => {
        this.setState(
          { value }
        );
    };

    render() {
        const { value } = this.state;

        return (
            <div className="main">
                <div className="menuButton" id="menuButton" onClick={()=>{this.toggleNav()}}></div>
                <div className="logoMain">
                    <Link to='/'>
                        <img src={logo} alt='mem' />
                    </Link>
                    <div className="title">
                        <h2>Portfolio</h2>
                    </div>
                </div>
                <MobileNav />
                <div className="portfolio-panel">
                    <div className="dropdown">
                        <Select 
                        options={this.state.options}
                        value={value}
                        onChange={this.handleChange}
                        defaultValue='Abasolo'
                        />
                    </div>
                    {
                        this.state.projects.filter(project => project.fields.project_title === this.state.value.value).slice(0).reverse().map((project) => {
                            return (
                                <div className="item" key={project.fields.title}>
                                    <img src={project.fields.image.fields.file.url} alt={project.fields.title} />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        )
    }
}
