import React, { Component } from 'react'
import * as contentful from 'contentful'
import { Link } from 'react-router-dom'
import '../../App.scss'
import logo from '../../img/logo-new.svg'
import MobileNav from '../../components/mobilenav'

export default class Press extends Component {
    state = {
        press: []
    }

    componentDidMount() {
        const client = contentful.createClient({
            space: 'flc7i8lgaghg',
            environment: 'master',
            accessToken: 'lpvop-BO7tm7MvJ9tlzT9oAvyy1OmlvZuQ9DZKiGOQg'
          })

          client.getEntries({content_type: 'press'})
          .then((response) => this.setState({
            press: response.items
          })
          )
          .catch(console.error)
    }

    toggleNav = () => {
        document.getElementById('mobileNav').style.display = "flex"
        document.getElementById('menuButton').style.display = "none"
    }

    render() {
        return (
            <div className="main">
                <div className="menuButton" id="menuButton" onClick={()=>{this.toggleNav()}}></div>
                <div className="logoMain">
                    <Link to='/'>
                        <img src={logo} alt='mem' />
                    </Link>
                    <div className="title">
                        <h2>Press</h2>
                    </div>
                </div>
                <MobileNav />
                <div className="books-panel press-panel">
                    {
                        this.state.press.map((pres) => {
                            return (
                                <div className="book press" key={pres.fields.title}>
                                    <a href={pres.fields.url} key={pres.fields.title} target="_blank" rel="noopener noreferrer">
                                        <img src={pres.fields.image.fields.file.url} alt={pres.fields.title} />
                                    </a>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        )
    }
}
