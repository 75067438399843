import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../App.scss'
import logo from '../../img/logo-new.svg'
import MobileNav from '../../components/mobilenav'

export default class About extends Component {

    toggleNav = () => {
        document.getElementById('mobileNav').style.display = "flex"
        document.getElementById('menuButton').style.display = "none"
    }

    render() {
        return (
            <div className="main">
                <div className="menuButton" id="menuButton" onClick={()=>{this.toggleNav()}}></div>
                <div className="logoMain">
                    <Link to='/'>
                        <img src={logo} alt='mem' />
                    </Link>
                    <div className="title">
                        <h2>About</h2>
                    </div>
                </div>
                <MobileNav />
                <div className="about">
                    <div className="picture">
                    </div>
                    <div className="content">
                        <h2>María Emilia Moncayo H. (Quito, Ecuador, 1984)</h2>
                        <p>
                        María Emilia Moncayo Hurtado descubre su pasión por la fotografía a los 6 años de edad. Jugaba a ser la fotógrafa oficial de su familia a su temprana edad cuando tomaba prestada la cámara fotográfica de su padre y esta se convertía en su mejor entretenimiento. Desde pequeña creció rodeada de naturaleza y animales, lo que la llevó a explorar su arte en este ámbito, descubriendo diferentes técnicas y facetas por si misma. 
                        <br/><br/>
                        Amante del campo, la naturaleza, la aventura, la fotografía, el arte y de un buen vino. Apasionada de la vida y de mirar con contemplación, nunca a dado por sentado un atardecer y la belleza de la vida en todas sus formas y simpleza. 
                        <br/><br/>
                        Esposa, madre, enamorada de su familia y de las cosas simples! Curiosa de naturaleza y de confiar en lo desconocido. Soñadora y ejecutora, quien gusta de viajar a lugares poco conocidos donde viven de manera distinta a la suya, reconociendo que el conocer nuevas culturas y espacios nos enriquece el alma! 
                        <br/><br/>
                        Autora y productora de los libros <i>Cuatro Andares, Caballos de Crianza en Ecuador (2010), Tierra de Chagras (2013), Senderos de Toquilla (2017), Amanecer al Galope (2018) y Simplicidad (2020)</i>. Publica varias fotos de su autoría en el libro <i>Oaxaca-México (2011)</i>, y en el libro <i>Haciendo Caminos al Andar (2014)</i>, el cual también produce. 
                        <br/><br/>
                        Ha realizado estudios dentro y fuera del país, iniciando en la Universidad San Francisco de Quito (2007), en Nueva York en el Interational Center of Photography “ICP” (2009); en México con la icónica fotógrafa Mary Ellen Mark de Nueva York (2011). Otros workshops han complementado su carrera. 
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
