import React, { Component } from 'react'
import * as contentful from 'contentful'
import { Link } from 'react-router-dom'
import '../../App.scss'
import logo from '../../img/logo-new.svg'
import MobileNav from '../../components/mobilenav'

export default class Prints extends Component {
    state = {
        prints: []
    }

    componentDidMount() {
        const client = contentful.createClient({
            space: 'flc7i8lgaghg',
            environment: 'master',
            accessToken: 'lpvop-BO7tm7MvJ9tlzT9oAvyy1OmlvZuQ9DZKiGOQg'
          })

          client.getEntries({content_type: 'prints'})
          .then((response) => this.setState({
            prints: response.items
          })
          )
          .catch(console.error)
    }

    toggleNav = () => {
        document.getElementById('mobileNav').style.display = "flex"
        document.getElementById('menuButton').style.display = "none"
    }

    render() {
        return (
            <div className="main">
                <div className="menuButton" id="menuButton" onClick={()=>{this.toggleNav()}}></div>
                <div className="logoMain">
                    <Link to='/'>
                        <img src={logo} alt='mem' />
                    </Link>
                    <div className="title">
                        <h2>Fine Art</h2>
                    </div>
                </div>
                <MobileNav />
                <div className="left-panel prints">
                    <p>
                    <h2>Fine Art</h2>
                    “El arte nos permite encontrarnos y perdernos al mismo tiempo.” 
                    Thomas Merton
                    <br/>
                    <br/>
                    Arte que mueve corazones, ilumina almas. Creando una conexión con la naturaleza, nuestras raíces y la expansión de nuestro interior! 
                    <br/>
                    <br/>
                    Escoge la Obra que te gusta, nosotros nos encargamos de entregártela en el tamaño que necesitas! 
                    <br/>
                    <br/>
                    <br/>
                    Precios varían de acuerdo a los diferentes tamaños (no incluyen marco)
                    (precios exclusivos dentro de Ecuador).
                    <br/><br/>
                    40x60cm <div className="spacer"></div>&nbsp;&nbsp; $380<br/>
                    60x90cm <div className="spacer"></div>&nbsp;&nbsp; $520<br/>
                    70x105cm <div className="spacer"></div> $680 <br/>
                    80x120cm <div className="spacer"></div> $790<br/>
                    95x150cm <div className="spacer"></div> $990<br/>
                    </p>
                </div>
                <div className="portfolio-panel prints">
                {
                    this.state.prints.slice(0).map((print) => {
                        return (
                            <div className="item" key={print.fields.image.fields.file.url}>
                                <img src={print.fields.image.fields.file.url} alt={print.fields.image.fields.file.url} />
                                <a href="https://wa.me/593999038381?text=Hola,%20estoy%20interesado/a%20" target="_blank" rel="noopener noreferrer">
                                    <button>Comprar</button>
                                </a>
                            </div>
                        );
                    })
                }
                </div>
            </div>
        )
    }
}
