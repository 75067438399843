import React, { Component } from 'react'
import * as contentful from 'contentful'
import { Link } from 'react-router-dom'
import '../../App.scss'
import logo from '../../img/logo-new.svg'

import MobileNav from '../../components/mobilenav'

export default class Books extends Component {
    state = {
        books: []
    }

    componentDidMount() {
        const client = contentful.createClient({
            space: 'flc7i8lgaghg',
            environment: 'master',
            accessToken: 'lpvop-BO7tm7MvJ9tlzT9oAvyy1OmlvZuQ9DZKiGOQg'
          })

          client.getEntries({content_type: 'books'})
          .then((response) => this.setState({
            books: response.items
          }, ()=> {
            console.log(this.state.books)
          })
          )
          .catch(console.error)
    }

    toggleNav = () => {
        document.getElementById('mobileNav').style.display = "flex"
        document.getElementById('menuButton').style.display = "none"
    }

    render() {
        return (
            <div className="main">
                <div className="menuButton" id="menuButton" onClick={()=>{this.toggleNav()}}></div>
                <div className="logoMain">
                    <Link to='/'>
                        <img src={logo} alt='mem' />
                    </Link>
                    <div className="title">
                        <h2>Books</h2>
                    </div>
                </div>
                <MobileNav />
                <div className="books-panel">
                    {
                        this.state.books.map((book) => {
                            return (
                                <div className="book" key={book.fields.title}>
                                    <img src={book.fields.featuredImage.fields.file.url} alt={book.fields.title} />
                                    <h2>{book.fields.title}</h2>
                                    <p style={{'whiteSpace':'pre-line'}}>{book.fields.description}</p>
                                    {book.fields.price && <h3 style={{ fontSize: '24px' }}>{`PVP $${book.fields.price}`}</h3>}
                                    <a href="https://wa.me/593999038381?text=Hola,%20estoy%20interesado/a%20" target="_blank" rel="noopener noreferrer">
                                        <button>Comprar</button>
                                    </a>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        )
    }
}
